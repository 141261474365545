import IntlCurrencyInput from "react-intl-currency-input";
import {
  X
} from "react-bootstrap-icons";
import { Tooltip } from "../tooltip/Tooltip";

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  }
};

export const LinhaPrecoMedio = ({ registro, index, remove, handleChange }) => {

  const { data, operacao, quantidade, preco, taxas, apuracao } = registro;

  let apuracaocomLucro = apuracao > 0;
  let apuracaocomPrejuizo = apuracao < 0;

  let cor = "";
  if (apuracaocomLucro)
    cor = "lightgreen";
  if (apuracaocomPrejuizo)
    cor = "lightcoral";

  const firsLine = index === 0;

  return (
    <tr key={index} style={{
      display: "inline-flex",
      background: "transparent",
      padding: 0
    }}>

      {/* REMOVER */}
      {!firsLine && (
        <th scope="row" className="text-end">
          <Tooltip text="Remover operação" posicao="right">
            <button
              className="btn btn-outline-danger "
              onClick={() => remove(index)}
            >
              <X size={23} className="" />
            </button>
          </Tooltip>
        </th>
      )}

      {firsLine && (
        <th scope="row" className="text-end" style={{
          zIndex: "-99"
        }}>

          <button
            className="btn btn-outline-secondary  disabled"
            onClick={() => remove(index)}
          >
            <X size={23} className="" disabled />
          </button>

        </th>
      )}

      {/* DATA */}
      <td className="">
        <input
          type="date"
          name="data"
          value={data}
          className="form-control"
          onChange={(e, val, maskedValue) => {
            handleChange(e, index, maskedValue);
          }}
          style={{
            minWidth: 160,
            maxWidth: 160
          }}
        />
      </td>

      {/* OPERAÇÃO */}
      <td className="" style={{
        minWidth: 105,
        maxWidth: 105
      }}>
        <select
          name="operacao"
          className="form-select"
          aria-label="Default select example"
          value={operacao}
          disabled={firsLine}
          onChange={(e, val, maskedValue) => {
            handleChange(e, index, maskedValue);
          }}
        >
          <option value="1">COMPRA</option>
          <option value="2">VENDA</option>
        </select>
      </td>

      {/* QUANTIDADE */}
      <td className="" style={{
        minWidth: 85,
        maxWidth: 85
      }}>
        <input
          type="number"
          value={quantidade}
          name="quantidade"
          className="form-control"
          placeholder="0"
          onChange={(e, val, maskedValue) => {
            handleChange(e, index, maskedValue);
          }}
        />
      </td>

      {/* PREÇO */}
      <td className="" style={{
        minWidth: 140,
        maxWidth: 140
      }}>
        <IntlCurrencyInput
          className="form-control"
          value={preco}
          name="preco"
          currency="BRL"
          config={currencyConfig}
          onChange={(e, val, maskedValue) => {
            handleChange(e, index, maskedValue);
          }}
        />
      </td>

      {/* TAXA */}
      <td className="" style={{
        minWidth: 140,
        maxWidth: 140
      }}>
        <IntlCurrencyInput
          className="form-control"
          value={taxas}
          name="taxas"
          currency="BRL"
          config={currencyConfig}
          onChange={(e, val, maskedValue) => {
            handleChange(e, index, maskedValue);
          }}
        />
      </td>

      {/* APURAÇÃO */}
      <td className="" style={{
        minWidth: 140,
        maxWidth: 140
      }}>
        <IntlCurrencyInput
          className="form-control readonly"
          style={{ backgroundColor: cor }}
          value={apuracao}
          name="apuracao"
          disabled={true}
          currency="BRL"
          config={currencyConfig}
        />
      </td>
    </tr>
  );
};