import "./styles.css";

import { Footer, CalculoPrecoMedio, ExplicacaoPrecoMedio } from "./pages";

function PagePrecoMedio() {

  return (
    <div className="page">
      <CalculoPrecoMedio/>

      <ExplicacaoPrecoMedio />

      <Footer />
    </div>
  );
}

export default PagePrecoMedio;
