import "./styles.css";

import { CalculoRateio, ExplicacaoRateio, Footer } from "./pages";

function PageRateio() {

  return (
    <div className="page">
      <CalculoRateio/>

      <ExplicacaoRateio />

      <Footer />
    </div>
  );
}

export default PageRateio;
