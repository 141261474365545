import { useNavigate } from "react-router-dom";
import "../../styles.css";
import { Tooltip } from "../../components/tooltip/Tooltip";

export const PoliticaPrivacidade = () => {

  const navigate = useNavigate();

  const handleToAverage = (e) => {
    e.preventDefault();
    navigate("/precomedio");
  };

  const handleToRateio = (e) => {
    e.preventDefault();
    navigate("/rateiotaxa");
  };

  return (
    <div className="d-flex justificado">

      <div className="p-2" style={{
        flexGrow: 1,
      }}>
        {/* LEFT MAIN*/}
      </div>

      <div className="p-2" style={{
        flexGrow: 2,
        maxWidth: '1020px'
      }}>
        <div className="d-flex flex-row">
          <div className="p-2" style={{
            flexGrow: 1,
          }}>
            {/* LEFT SECONDARY*/}
          </div>
          <article>

            {/* NAV */}
            <div className="row text-center">
              <div className="col-sm-4 offset-sm-4 text-break" style={{
                paddingTop: 0,
                marginBottom: 0,
                fontSize: 30,
                color: 'lightskyblue'
              }}>
                <Tooltip text="Calculadora de Preço Médio" posicao="bottom">
                  <button className="btn btn-sm btn-outline-secondary me-1 ms-1"
                    onClick={(e) => handleToAverage(e)}>
                    Preço Médio
                  </button>
                </Tooltip>

                <Tooltip text="Calculadora Rateio de Taxas" posicao="bottom">
                  <button className="btn btn-sm btn-outline-secondary me-1 ms-1"
                    onClick={(e) => handleToRateio(e)}>

                    Rateio Taxas
                  </button>
                </Tooltip>
              </div>
            </div>
            {/* END NAV */}

            <h2 className="text-success mt-4"> Políticas de Privacidade e Responsabilidade </h2>

            <div className="py-4">
              <p>A privacidade dos visitantes do meuprecomedio.com.br é nossa prioridade, e estamos comprometidos em protegê-la. Esta política e os termos a seguir explicam como tratamos suas informações pessoais.</p>

              <p>Ao utilizar o meuprecomedio.com.br, você concorda com a coleta e o uso de seus dados pessoais conforme descrito nesta política.</p>
            </div>

            <h5 className="text-success mt-4"> Coleta de informações</h5>

            <div className="py-4">
              <p>Podemos coletar, armazenar e usar as seguintes informações pessoais:</p>
              <ul>
                <li>Dados sobre o seu computador, como endereço IP, localização geográfica, tipo e versão do navegador, duração da visita e sistema operacional.</li>
                <li>Informações relacionadas à sua navegação no site, incluindo fonte de referência, duração da visita, visualizações de página e percurso de navegação.</li>
                <li>Informações fornecidas ao entrar em contato conosco, como nome, e-mail e outras informações incluídas nas mensagens, como conteúdo e metadados.</li>
                <li>Dados gerados durante o uso do site, como frequência de acesso, páginas visitadas e circunstâncias de navegação.</li>
                <li>Qualquer outra informação pessoal que você nos envie.</li>
              </ul>
            </div>

            <h5 className="text-success mt-4">  Política de privacidade</h5>

            <div className="py-4">
              <p>O meuprecomedio.com.br utiliza cookies e/ou web beacons quando o usuário acessa suas páginas. Os cookies, pequenos arquivos de texto, são associados ao navegador de um dispositivo específico e ajudam a melhorar a experiência do usuário no site. Eles armazenam preferências, como informações de carrinhos de compras, e fornecem dados anônimos a ferramentas de terceiros, como o Google Analytics. Assim, os cookies otimizam a navegação no site. No entanto, se preferir, você pode desativar os cookies diretamente nas configurações do seu navegador.</p>
              <p>Os cookies utilizados pelo meuprecomedio.com.br podem ser instalados diretamente por nós, ou por servidores de terceiros que prestam serviços como publicidade ou fornecimento de conteúdos. Esses cookies podem controlar a exibição de anúncios e outros conteúdos em momentos específicos da navegação. Você pode verificar e gerenciar os cookies no seu navegador conforme as instruções disponíveis nas configurações e manuais do próprio navegador.</p>
              <p>Além disso, usamos empresas de publicidade de terceiros para exibir anúncios enquanto você navega no meuprecomedio.com.br. Essas empresas podem coletar informações (exceto nome, endereço, e-mail ou telefone) sobre suas visitas a este e a outros sites para oferecer anúncios de produtos e serviços de seu interesse, proporcionando uma experiência personalizada. Para saber mais sobre essa prática e como impedir o uso dessas informações, confira em Google Privacidade e Termos
                <a href="https://policies.google.com/technologies/ads?hl=pt-BR" target="_blank" rel="noreferrer"
                  className="ms-2 link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text-success"
                  style={{
                    textDecoration: "none !important"
                  }}
                >
                  Publicidade
                </a> e <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noreferrer"

                  className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text-success"
                  style={{
                    textDecoration: "none !important"
                  }}
                >
                  Como o google usa as informações
                </a>.</p>
            </div>

            <h5 className="text-success mt-4"> Dados pessoais coletados para os seguintes propósitos e usando os seguintes serviços</h5>

            <div className="py-4">
              <p>Estatísticas: Utilizamos o Google Analytics com IP anônimo para coletar dados estatísticos. Informações pessoais como cookies e dados de uso são processadas. Se preferir, você pode desativar o Google Analytics instalando o seu
                <a href="https://tools.google.com/dlpage/gaoptout?hl=pt-br" target="_blank" rel="noreferrer"

                  className="ms-2 link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text-success"
                  style={{
                    textDecoration: "none !important"
                  }}
                >
                  addon
                </a>.</p>

              <p>Anúncios: Utilizamos o Google AdSense e o programa de associados da Amazon para veicular anúncios. Esses serviços processam dados pessoais como cookies e dados de uso. Você pode desativar a personalização de anúncios do Google nas
                <a href="https://myadcenter.google.com/home?sasb=true&ref=ad-settings" target="_blank" rel="noreferrer"

                  className="ms-2 link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text-success"
                  style={{
                    textDecoration: "none !important"
                  }}
                >
                  Configurações de anúncios
                </a>, e também é recomendável consultar a
                <a href="https://associados.amazon.com.br/help/operating/participation/" target="_blank" rel="noreferrer"

                  className="ms-2 link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text-success"
                  style={{
                    textDecoration: "none !important"
                  }}
                >
                  Política de privacidade do programa Amazon Associados
                </a>
                .</p>

              <p>Hospedagem e infraestrutura: O site é hospedado pela Firebase, que pode coletar dados pessoais conforme especificado na política de privacidade deles.</p>

              <p>Comunicação: Se você nos enviar um e-mail com informações pessoais, essas informações serão usadas apenas para responder à sua mensagem.</p>
            </div>

            <h5 className="text-success mt-4"> Mais informações sobre dados pessoais</h5>

            <div className="py-4">
              <p> O meuprecomedio.com.br não vende nem aluga dados de usuários para qualquer finalidade. O uso dos dados está estritamente de acordo com esta política de privacidade, em conformidade com a Lei nº 13.709 (LGPD).</p>

              <p>Vale destacar que o usuário é o único proprietário de seus dados e pode optar por não compartilhá-los, desativando os cookies nas configurações do seu navegador.</p>
            </div>

            <h5 className="text-success mt-4"> Links para websites de terceiros ou recursos</h5>

            <div className="py-4">
              <p>O meuprecomedio.com.br pode conter links para outros sites. Ao clicar nesses links, você será redirecionado para sites que possuem seus próprios termos de uso e políticas de privacidade. O meuprecomedio.com.br não se responsabiliza pelo conteúdo, informações, serviços, produtos ou anúncios exibidos em outros sites, e o acesso a esses sites é feito por sua conta e risco.</p>

              <p>Esses links são fornecidos apenas por conveniência e não representam endosso ou apoio do meuprecomedio.com.br em relação a outros sites, seus recursos, conteúdos, serviços ou produtos. O meuprecomedio.com.br também não se responsabiliza pelo conteúdo ou pelas práticas desses sites externos.</p>
            </div>

            <h5 className="text-success mt-4"> Armazenamento de dados</h5>

            <div className="py-4">
              <p>O meuprecomedio.com.br utiliza fornecedores de terceiros e parceiros de hospedagem para fornecer o hardware, software, rede, armazenamento e outras tecnologias necessárias para manter o funcionamento do site.

                O meuprecomedio.com.br detém a propriedade do código, banco de dados e todos os direitos sobre as aplicações utilizadas em sua plataforma.</p>
            </div>

            <h5 className="text-success mt-4"> Segurança de informação</h5>

            <div className="py-4">
              <p>Adotamos medidas para garantir a segurança de suas informações pessoais. No entanto, não podemos assegurar que hackers ou pessoas não autorizadas não consigam acessar seus dados, mesmo com nossos esforços. Ao usar os serviços do meuprecomedio.com.br, suas informações poderão passar por infraestruturas de terceiros, sobre as quais não temos controle.

                O meuprecomedio.com.br não pode garantir a proteção, nem esta política de privacidade se aplica às informações que você transmitir para outros usuários. Recomendamos que você nunca compartilhe informações pessoais que possam identificá-lo com outros usuários.</p>
            </div>

            <h5 className="text-success mt-4"> Compartilhamento de informação perante à lei</h5>

            <div className="py-4">
              <p>O compartilhamento de informações pode ser necessário para investigação, prevenção ou ação em casos de atividades ilegais, suspeita de fraude, possíveis ameaças à segurança física de qualquer pessoa, violações dos termos de serviço e política, ou conforme exigido por lei.</p>
            </div>

            <h5 className="text-success mt-4">Transferência de dados entre países </h5>

            <div className="py-4">
              <p>As informações coletadas podem ser armazenadas, processadas e transferidas entre os países onde operamos, permitindo seu uso de acordo com esta política e os termos estabelecidos.

                Os dados pessoais que você publicar em nosso site, ou enviar para publicação, podem ser acessíveis globalmente via Internet. Não podemos garantir que essas informações não sejam utilizadas ou mal utilizadas por terceiros.</p>
            </div>

            <h5 className="text-success mt-4"> Alterações</h5>

            <div className="py-4">
              <p>Podemos atualizar esta política de privacidade e os termos periodicamente, por isso recomendamos que você os verifique com regularidade. Caso sejam feitas alterações substanciais, notificaremos você por meio de um aviso destacado em nosso site.</p>
            </div>

            <h5 className="text-success mt-4">  Informação de contato</h5>

            <div className="py-4">
              <p>Dono e controlador de dados: <a href="mailto:leomar_sartor@unochapeco.edu.br?subject=Meu preço médio&body=Contato Meu Preço Médio" target="_blank" rel="noreferrer"

                className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text-success"
                style={{
                  textDecoration: "none !important"
                }}
              >Leomar Vaz Sartor</a></p>
            </div>
          </article>

          <div className="p-2" style={{
            flexGrow: 1,
          }}>
            {/* RIGHT SECONDARY*/}
          </div>
        </div>
      </div>

      <div className="p-2" style={{
        flexGrow: 1,
      }}>
        {/* RIGHT MAIN*/}
      </div>
    </div>
  );
};