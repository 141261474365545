import { Navigate, Route, Routes } from "react-router-dom";

import PagePrecoMedio from "../PagePrecoMedio";
import PageRateio from "../PageRateio";
import PagePoliticaPrivacidade from "../PagePoliticaPrivacidade";

export const AppRoutes = () => {
  return (
    <Routes>
       <Route path="/precomedio" element={<PagePrecoMedio />} />

       <Route path="/rateiotaxa" element={<PageRateio />} />

       <Route path="/politicaprivacidade" element={<PagePoliticaPrivacidade />} />

       <Route path="*" element={<Navigate to="/precomedio" />} />
    </Routes>
  );
};
