import IntlCurrencyInput from "react-intl-currency-input";
import {
  X
} from "react-bootstrap-icons";
import { Tooltip } from "../tooltip/Tooltip";

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  }
};

export const LinhaRateio = ({ registro, index, remove, handleChange }) => {

  const {  nomeativo, precototal, valorrateio } = registro;

  const firsLine = index === 0;

  return (
    <tr key={index} style={{
      display: "inline-flex",
      background: "transparent",
      padding: 0,
      marginTop: 0
    }}>

      {/* REMOVER */}
      {!firsLine && (
        <th scope="row" className="text-end">
          <Tooltip text="Remover operação" posicao="right">
            <button
              className="btn btn-outline-danger "
              onClick={() => remove(index)}
            >
              <X size={23} className="" />
            </button>
          </Tooltip>
        </th>
      )}

      {firsLine && (
        <th scope="row" className="text-end" style={{
          zIndex: "-99"
        }}>

          <button
            className="btn btn-outline-secondary  disabled"
            onClick={() => remove(index)}
          >
            <X size={23} className="" disabled />
          </button>

        </th>
      )}

      {/* NOME ATIVO */}
      <td className="">
        <input
          type="text"
          name="nomeativo"
          value={nomeativo}
          className="form-control"
          placeholder="XPTO"
          onChange={(e, val, maskedValue) => {
            handleChange(e, index, maskedValue);
          }}
          style={{
            minWidth: 95,
            maxWidth: 95
          }}
        />
      </td>

      {/* PREÇO TOTAL*/}
      <td className="" style={{
        minWidth: 140,
        maxWidth: 140
      }}>
        <IntlCurrencyInput
          className="form-control"
          value={precototal}
          //value={parseFloat(preco)}
          name="precototal"
          currency="BRL"
          config={currencyConfig}
          onChange={(e, val, maskedValue) => {
            handleChange(e, index, maskedValue);
          }}
        />
      </td>

      {/* APURAÇÃO */}
      <td className="" style={{
        minWidth: 140,
        maxWidth: 140
      }}>
        <IntlCurrencyInput
          className="form-control readonly"
          value={valorrateio}
          name="apuracao"
          disabled={true}
          currency="BRL"
          config={currencyConfig}
        />
      </td>
    </tr>
  );
};