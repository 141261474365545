import {
  Plus
} from "react-bootstrap-icons";
import { Tooltip } from "../tooltip/Tooltip";

export const Adicionar = ({ add }) => {

  return (
    <div>
        <div className="container">
          <div className="row my-3 pt-2 mt-0" style={{
            backgroundColor: "transparent"
          }}>
            <div className="col text-center" >
              <Tooltip text="Adicionar nova operação" posicao="top">
                <button className="btn btn-success" onClick={add} data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                  <Plus size={23} className="text-start" />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
    </div>
  );
};