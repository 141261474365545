import { Tooltip as BsTooltip } from "bootstrap"
import React, { useEffect, useRef } from "react"

export const Tooltip = ({children, text, posicao}) => {
    const childRef = useRef()

    useEffect(() => {
        const t = new BsTooltip(childRef.current, {
            title: text,
            placement: posicao,
            trigger: "hover"
        })

        return () => t.dispose()
    });

    return React.cloneElement(children, { ref: childRef })
}

