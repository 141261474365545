import { useEffect, useState, useRef } from "react";
import { FileEarmarkArrowDownFill, Paperclip } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { somarQuantidade, calcularPrecoMedio } from "../../functions";
import { X } from "react-bootstrap-icons";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Adicionar } from "../../components/adicionar/Adicionar";
import { Tooltip } from "../../components/tooltip/Tooltip";
import { LinhaPrecoMedio } from "../../components/linhaPrecoMedio/LinhaPrecoMedio";

const today = () => {
  var showdate = new Date();

  return showdate.getFullYear()
    + "-" + ('0' + (showdate.getMonth() + 1)).slice(-2)
    + "-" + ('0' + (showdate.getDate())).slice(-2);
};

export const CalculoPrecoMedio = () => {

  const navigate = useNavigate();

  const inputRef = useRef(null);

  const [show, setShow] = useState(false);
  const [nameFile, setNameFile] = useState("precomedio");
  const [successImport, setSuccessImport] = useState(false);
  const [errorImport, setErrorImport] = useState(false);
  const [qtdTotal, setQtdTotal] = useState("");
  const [precoMedio, setPrecoMedio] = useState("");
  const [inputFields, setInputFields] = useState([
    {
      data: today(),
      operacao: "1",
      quantidade: "",
      saldoCotas: "",
      preco: "",
      taxas: "",
      totalInvestido: "",
      apuracao: ""
    }
  ]);

  useEffect(() => {
    const somaQuantidade = somarQuantidade(inputFields);
    setQtdTotal(somaQuantidade);
    const precoMedio = calcularPrecoMedio(inputFields, false);
    setPrecoMedio(precoMedio);
  }, [inputFields]);

  const handleToRateio = (e) => {
    e.preventDefault();
    navigate("/rateiotaxa");
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const addInputField = (
    date = today(),
    op = "1",
    qtd = "",
    price = "",
    rate = "",
    totalInvested = "",
    apuration = "",
  ) => {
    setInputFields([
      ...inputFields,
      {
        data: date,
        operacao: op,
        quantidade: qtd,
        preco: price,
        taxas: rate,
        totalInvestido: totalInvested,
        apuracao: apuration,
      }
    ]);
  };

  const removeInputField = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);

    setInputFields(rows);
  };

  const handleChange = (event, index, mascara) => {
    const { name, value } = event.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  const handleCalcular = () => {
    const somaQuantidade = somarQuantidade(inputFields);
    setQtdTotal(somaQuantidade);
    calcularPrecoMedio(inputFields, true);
    const list = [...inputFields];
    setInputFields(list);
  };

  const handleSaveData = () => {
    const fileData = JSON.stringify(inputFields);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${nameFile}.json`;
    link.href = url;
    link.click();
    handleClose();
  };

  const handleOpenFile = (event) => {
    const fileObj = event.target.files[0];
    const reader = new FileReader();

    reader.readAsText(fileObj);

    reader.onload = (e) => {
      const { result } = e.target;

      let jsonFile = "";
      try {
        jsonFile = JSON.parse(result);
        setInputFields(jsonFile);
        setSuccessImport(true);
        setErrorImport(false);
      }
      catch (error) {
        setErrorImport(true);
        setSuccessImport(false);
      }
    };
  };

  const handleImportData = () => {
    inputRef.current.click();
  };

  return (
    <main style={{
      background: '#f8f8f8',
      position: 'relative',
      minHeight: '88vh',
      display: 'flex',
      paddingBottom: '30px'
    }}>

      <div className="container-fluid">

        {/* NAV */}
        <div className="row text-center mt-2">
          <div className="col-sm-4 offset-sm-4 text-break" style={{
            paddingTop: 0,
            marginBottom: 0,
            fontSize: 30,
            color: 'lightskyblue'
          }}>
            <Tooltip text="Calculadora de Preço Médio" posicao="bottom">
              <button className="btn btn-sm btn-secondary me-1 ms-1 disabled">
                Preço Médio
              </button>
            </Tooltip>

            <Tooltip text="Calculadora Rateio de Taxas" posicao="bottom">
              <button className="btn btn-sm btn-outline-secondary me-1 ms-1"
                onClick={(e) => handleToRateio(e)}>
                Rateio Taxas
              </button>
            </Tooltip>
          </div>
        </div>
        {/* END NAV */}

        {/* TITULO */}
        <div className="row text-center mt-4">

          {errorImport && (
            <div className="col-sm-6 offset-sm-3 text-break" style={{
              paddingTop: 10,
              marginBottom: 10,
              color: 'lightskyblue'
            }}>
              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Não foi desta vez!</strong> Parece que seu arquivo está corrompido.
                <button type="button" className="btn-close"
                  onClick={() => setErrorImport(false)}
                >
                </button>
              </div>
            </div>
          )}

          {successImport && (
            <div className="col-sm-6 offset-sm-3 text-break" style={{
              paddingTop: 10,
              marginBottom: 10,
              color: 'lightskyblue'
            }}>
              <div className="alert alert-success alert-dismissible fade show" role="alert">
                <strong>Sucesso!</strong> Suas negociações foram importadas com sucesso.
                <button type="button" className="btn-close"
                  onClick={() => setSuccessImport(false)}
                >
                </button>
              </div>
            </div>
          )}

          <div className="col-sm-4 offset-sm-4 text-break" style={{
            paddingTop: 10,
            marginBottom: 10,
            fontSize: 30,
            color: 'lightskyblue'
          }}>
            Calcular Preço Médio
          </div>

        </div>
        {/* END TITULO */}

          {/* TABLE */}
          <div className="table-responsive container">
            <table className="table table-borderless text-center" >
              <thead>
                <tr key="-1" style={{
                  display: "inline-flex",
                  backgroundColor: "transparent",
                }}>
                  {/* eslint-disable-next-line */}
                  <td scope="row" className="text-end" style={{
                    zIndex: "-99",
                    padding: 0,
                    minWidth: 51
                  }}>
                    <button
                      className="btn btn-outline-secondary  disabled"
                    >
                      <X size={23} className="" disabled />
                    </button>
                  </td>
                  <td className="text-success text-start" style={{
                    minWidth: 160,
                    maxWidth: 160,
                    backgroundColor: "transparent"
                  }}>Data</td>
                  <td className="text-success text-start" style={{
                    minWidth: 105,
                    maxWidth: 105,
                    backgroundColor: "transparent"
                  }}>Operação</td>
                  <td className="text-success text-start" style={{
                    minWidth: 85,
                    maxWidth: 85,
                    backgroundColor: "transparent"
                  }}>Qtd</td>
                  <td className="text-success text-start" style={{
                    minWidth: 140,
                    maxWidth: 140,
                    backgroundColor: "transparent"
                  }}>Preço</td>
                  <td className="text-success text-start" style={{
                    minWidth: 140,
                    maxWidth: 140,
                    backgroundColor: "transparent"
                  }}>Taxas</td>
                  <td className="text-success text-start" style={{
                    minWidth: 140,
                    maxWidth: 140,
                    backgroundColor: "transparent"
                  }}>Lucro/Prejuízo</td>
                </tr>
              </thead>
              <tbody>
                {inputFields.length !== 0 &&
                  inputFields.map((data, index) => {
                    return (
                      <div key={index.toString()}>

                        <LinhaPrecoMedio
                          registro={data}
                          index={index}
                          remove={() => removeInputField(index)}
                          handleChange={(e, index, mascara) =>
                            handleChange(e, index, mascara)
                          }
                        />

                      </div>
                    )
                  })}
              </tbody>
            </table>
          </div>
          {/* END TABLE */}

          {/* FUNÇÕES E INFORMAÇÕES */}
          <Adicionar
            add={() => addInputField()}
          />

          <div className="container ">
            <div className="row my-3 pt-1">
              <div className="col text-center">
                <Tooltip text="Apurar Lucro ou Prejuízo" posicao="top">
                  <button className="btn btn-outline-warning" onClick={() => handleCalcular()}>
                    Calcular
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>

          {/* DADOS */}
          <div className="row text-center" style={{
            marginBottom: 5,
            fontSize: 25,
            color: 'lightskyblue'
          }}>
            <div className="col-sm-4 offset-sm-4 text-break">
              Saldo Cotas: {qtdTotal}
            </div>
          </div>

          <div className="row text-center" style={{
            marginBottom: 5,
            fontSize: 25,
            color: 'lightskyblue'
          }}>
            <div className="col-sm-4 offset-sm-4 text-break">
              Preço Médio: {precoMedio}
            </div>
          </div>

          <div className="col-sm-12 text-center pt-3">
            <Tooltip text="Importar cópia" posicao="bottom">
              <button
                className="btn btn-outline-primary mt-2 ms-2 me-2"
                onClick={handleImportData}
              >
                <Paperclip size={35} className="pe-2" />
                Importar
              </button>
            </Tooltip>

            <input
              ref={inputRef}
              className="hidden"
              multiple={false}
              type="file"
              accept=".json"
              onChange={(e) => handleOpenFile(e)}
            />

            <Tooltip text="Salvar cópia" posicao="bottom">
              <button
                className="btn btn-outline-success mt-2 me-2 ms-2"
                onClick={() => handleShow()}
              >
                <FileEarmarkArrowDownFill size={35} className="pe-2" />
                Download
              </button>
            </Tooltip>
          </div>
          {/* END FUNÇÕES E INFORMAÇÕES */}

        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Salvar operações</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Nome arquivo:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="nome_meu_ativo"
                  autoFocus
                  value={nameFile}
                  onChange={(e) => setNameFile(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="btn btn-outline-secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="btn btn-success" onClick={handleSaveData}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>
    </main>
  );
};
