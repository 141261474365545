import { useState } from "react";
import "../../styles.css";
import { Tooltip } from "../../components/tooltip/Tooltip";
import IntlCurrencyInput from "react-intl-currency-input";
import { LinhaRateio } from "../../components/linhaRateio/LinhaRateio";
import { Adicionar } from "../../components/adicionar/Adicionar";
import { calcularRateio } from "../../functions/calcularRateio";
import { X } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  }
};

export const CalculoRateio = () => {

  const navigate = useNavigate();

  const [totalNota, setTotalNota] = useState("");
  const [inputFields, setInputFields] = useState([
    {
      nomeativo: "",
      precototal: "",
      valorrateio: ""
    }
  ]);

  const handleToAverage = (e) => {
    e.preventDefault();
    navigate("/precomedio");
  };

  const addInputField = (
    nome = "",
    preco = "",
    valor = "",
  ) => {
    setInputFields([
      ...inputFields,
      {
        nomeativo: nome,
        precototal: preco,
        valorrateio: valor,
      }
    ]);
  };

  const removeInputField = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleChange = (event, index, mascara) => {
    const { name, value } = event.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  const handleCalcular = () => {
    calcularRateio(inputFields, totalNota, true);
    const list = [...inputFields];
    setInputFields(list);
  };

  return (
    <main style={{
      background: '#f8f8f8',
      position: 'relative',
      minHeight: '88vh',
      display: 'flex',
      paddingBottom: '30px'
    }}>
      <div className="container-fluid" style={{}}>

        {/* NAV */}
        <div className="row text-center mt-2">
          <div className="col-sm-4 offset-sm-4 text-break" style={{
            paddingTop: 0,
            marginBottom: 0,
            fontSize: 30,
            color: 'lightskyblue'
          }}>
            <Tooltip text="Calculadora de Preço Médio" posicao="bottom">
              <button className="btn btn-sm btn-outline-secondary me-1 ms-1"
                onClick={(e) => handleToAverage(e)}>
                Preço Médio
              </button>
            </Tooltip>

            <Tooltip text="Calculadora Rateio de Taxas" posicao="bottom">
              <button className="btn btn-sm btn-secondary me-1 ms-1 disabled"
              >
                Rateio Taxas
              </button>
            </Tooltip>
          </div>
        </div>
        {/* END NAV */}

        {/* TITULO */}
        <div className="row text-center mt-4">
          <div className="col-sm-4 offset-sm-4 text-break " style={{
            fontSize: 30,
            color: 'lightskyblue'
          }}>
            Rateio Taxas
          </div>
        </div>
        {/* END TITULO */}

        {/* DADOS */}
        <div className="row text-center mt-2">
          <div className="col-sm-4 offset-sm-4 text-break text-success" style={{
            paddingTop: 10,
            marginBottom: 10,
            fontSize: 17,
            color: 'lightskyblue'
          }}>
            Total da Nota
          </div>

          <div>
            <IntlCurrencyInput
              className="form-control"
              name="preco"
              currency="BRL"
              config={currencyConfig}
              onChange={(e) => {
                setTotalNota(e.target.value);
              }}
              style={{
                minWidth: 160,
                maxWidth: 160,
                display: "inline-flex"
              }}
            />
          </div>

          <div className="col-sm-4 offset-sm-4 text-break mt-4" style={{
            paddingTop: 10,
            marginBottom: 10,
            fontSize: 17,
            color: '#9ca2a7'
          }}>
            Operações da Nota
          </div>

        </div>
        {/* END DADOS */}

        {/* TABLE */}
        <div className="table-responsive container">
          <table className="table table-borderless text-center" >
            <thead>
              <tr key="-1" style={{
                display: "inline-flex",
                backgroundColor: "transparent",
              }}>
                {/* eslint-disable-next-line */}
                <td scope="row" className="text-end" style={{
                  zIndex: "-99",
                  padding: 0,
                  minWidth: 51
                }}>
                  <button
                    className="btn btn-outline-secondary  disabled"
                  >
                    <X size={23} className="" disabled />
                  </button>
                </td>
                <td className="text-success text-start" style={{
                  minWidth: 95,
                  maxWidth: 95,
                  backgroundColor: "transparent"
                }}>Ativo</td>
                <td className="text-success text-start" style={{
                  minWidth: 140,
                  maxWidth: 140,
                  backgroundColor: "transparent"
                }}>Total Operação</td>
                <td className="text-success text-start" style={{
                  minWidth: 140,
                  maxWidth: 140,
                  backgroundColor: "transparent"
                }}>Rateio</td>
              </tr>
            </thead>
            <tbody>
              {inputFields.length !== 0 &&
                inputFields.map((data, index) => {
                  return (
                    <div key={index.toString()}>

                      <LinhaRateio
                        registro={data}
                        index={index}
                        remove={() => removeInputField(index)}
                        handleChange={(e, index, mascara) =>
                          handleChange(e, index, mascara)
                        }
                      />

                    </div>
                  )
                })}
            </tbody>
          </table>
        </div>
        {/* END TABLE */}

        {/* FUNÇÕES E INFORMAÇÕES */}
        <Adicionar
          add={() => addInputField()}
        />

        <div className="col-sm-12 text-center pt-3">
          <Tooltip text="Apurar Lucro ou Prejuízo" posicao="top">
            <button className="btn btn-outline-warning" onClick={() => handleCalcular()}>
              Calcular
            </button>
          </Tooltip>
        </div>
        {/* END FUNÇÕES E INFORMAÇÕES */}

      </div>
    </main >
  );
};
