import NotaCorretagem from '../../images/ModeloNota2.png'
import RateioTaxas from '../../images/ResultadoRateio.jpg'

export const ExplicacaoRateio = () => {
  return (
    <div className="d-flex">

      <div className="p-2" style={{
        flexGrow: 1,
      }}>
        {/* LEFT MAIN*/}
      </div>

      <div className="p-2" style={{
        flexGrow: 2,
        maxWidth: '1020px'
      }}>
        <div className="d-flex flex-row">
          <div className="p-2" style={{
            flexGrow: 1,
          }}>
            {/* LEFT SECONDARY*/}
          </div>
          <article className="justificado">
            <h2 className="text-success mt-4"> O que é o rateio? </h2>

            <div className="py-4">
              <p>O termo rateio está relacionado ao processo de alocação e divisão de custos comuns. Ele ajuda o investidor a distribuir os custos de taxas,
                emolumentos e outros entre os ativos de uma mesma nota para calcular o preço médio indivídual de cada ativo.</p>
            </div>

            <h2 className="text-success mt-4"> Como realizar? </h2>

            <div className="py-4">
              <p>Observe a nota a seguir e seus valores:</p>

              <img src={NotaCorretagem} className="mt-2 rounded mx-auto d-block img-fluid" alt="Exemplo de Nota de Corretagem"></img>

              <h5 className="text-success mt-4"> Algumas considerações </h5>

              <div className="py-2">
                <ol className="list-group list-group-flush list-group-numbered">

                  <li className="list-group-item">
                    Desconsideramos a quantidade dos ativos, apenas utilizamos o total por ativo, como por exemplo o <div className="fw-bold" style={{
                      display: "inline"
                    }}>BTCI</div> representado em azul, onde agrupamo-os, totalizando
                    <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-dark bg-dark bg-opacity-10 border border-dark border-opacity-10 rounded-2">
                      R$ 17,54
                    </small>
                  </li>

                  <li className="list-group-item">O total das operações<small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-dark bg-dark bg-opacity-10 border border-dark border-opacity-10 rounded-2">
                    R$ 437,19
                  </small>representada em verde, é diferente do total da nota<small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-dark bg-dark bg-opacity-10 border border-dark border-opacity-10 rounded-2">
                      R$ 437,31
                    </small>também representada em verde,
                    assim ultizamos o total da nota.
                  </li>

                  <li className="list-group-item">
                    As taxas são totalizadas em<small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-dark bg-dark bg-opacity-10 border border-dark border-opacity-10 rounded-2">
                      R$ 0,12
                    </small>representada em vermelho, que por sua vez é o valor utilizado.
                  </li>
                </ol>
              </div>

              <h5 className="text-success mt-4"> Rateio final </h5>
              <img src={RateioTaxas} className="mt-4 rounded mx-auto d-block img-fluid" alt="Resultado do Rateio das Taxas"></img>
            </div>

            <h2 className="text-success">Observações</h2>

            <div className="py-4">

              <p> <div style={{
                display: "inline"
              }}> * </div> Consideramos o total da nota subtraindo o total das operações, dividindo o valor ponderadamente entre
                os ativos. </p>

              <p className="text-decoration-underline"> <div style={{
                display: "inline",
                textDecoration: "none"
              }}> * </div> Este site não se responsabiliza pela precisão dos dados, é critério exclusivo do investidor verificar e atentarse pelos
                dados fornecidos. Ele funciona apenas como ferramenta auxiiar com o proposito de ajudar. </p>
            </div>

            <h2 className="text-success">Quer saber mais?</h2>

            <div className="py-4">
              <p> As taxas B3 (Bolsa de Valores Brasileira) cobradas podem alterar-se no decorrer do tempo,
                e alguns de seus valores para fins de cálculos podem ser encontrados a seguir, consulte: </p>

              <p className="pt-3 fs-6">
                {/* eslint-disable-next-line */}
                <a href="https://www.b3.com.br/pt_br/produtos-e-servicos/tarifas/listados-a-vista-e-derivativos/renda-variavel/tarifas-de-acoes-e-fundos-de-investimento/a-vista/"
                  className="me-2 link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text-success"
                  style={{
                    textDecoration: "none !important"
                  }}
                >
                  Tarifas de Investimentos
                </a>
                e
                <a href="https://www.b3.com.br/pt_br/produtos-e-servicos/tarifas/mercado-de-balcao/tarifas-de-renda-variavel/"
                  className="ms-2 link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text-success"
                  style={{
                    textDecoration: "none !important"
                  }}
                >
                  Tarifas de Renda Variável
                </a>
              </p>
            </div>
          </article>

          <div className="p-2" style={{
            flexGrow: 1,
          }}>
            {/* RIGHT SECONDARY*/}
          </div>
        </div>
      </div>

      <div className="p-2" style={{
        flexGrow: 1,
      }}>
        {/* RIGHT MAIN*/}
      </div>
    </div>
  );
};

