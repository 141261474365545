export const ExplicacaoPrecoMedio = () => {
  return (
    <div className="d-flex">

      <div className="p-2" style={{
        flexGrow: 1,
      }}>
        {/* LEFT MAIN*/}
      </div>

      <div className="p-2" style={{
        flexGrow: 2,
        maxWidth: '1020px'
      }}>
        <div className="d-flex flex-row">
          <div className="p-2" style={{
            flexGrow: 1,
          }}>
            {/* LEFT SECONDARY*/}
          </div>

          <article className="p-2 justificado" style={{
            flexGrow: 2,
          }}>
            <h2 className="text-success mt-4"> O que é o preço médio? Como calcular?</h2>

            <div className="py-4">
              <p>O preço médio é a média dos valores pagos pelas aquisições de um mesmo ativo na bolsa, considerando apenas as compras em momentos e preços diferentes.</p>

              <p>Por exemplo, considere as seguintes operacoes de um ativo XPTO.</p>

              <ol className="list-group list-group-flush list-group-numbered">

                <li className="list-group-item">
                  <small className="d-inline-flex me-2 px-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">
                    Compra
                  </small>

                  3 unidades a

                  <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-2">
                    R$ 39,10
                  </small>

                  com taxas de

                  <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-2">
                    R$ 0,03
                  </small>

                  totalizando a operação em

                  <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-2">
                    R$ 117,33
                  </small>

                  <div className="ms-4 pt-2 me-auto">
                    <div className="fw-bold">Preço Médio:
                      <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-dark bg-dark bg-opacity-10 border border-dark border-opacity-10 rounded-2">
                        R$ 39,11
                      </small>
                    </div>
                  </div>

                </li>
                <li className="list-group-item">
                  <small className="d-inline-flex me-2 px-1 fw-semibold text-danger bg-danger bg-opacity-10 border border-danger border-opacity-10 rounded-2">
                    Venda
                  </small>
                  2 unidades a

                  <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-2">
                    R$ 48,03
                  </small>
                  <span className="badge bg-priary"></span>

                  com taxas de

                  <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-2">
                    R$ 0,02
                  </small>

                  totalizando a operação em

                  <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-2">
                    R$ 96,06
                  </small>

                  <div className="ms-4 pt-2 me-auto">
                    <div className="fw-bold">Preço Médio:
                      <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-dark bg-dark bg-opacity-10 border border-dark border-opacity-10 rounded-2">
                        R$ 39,11
                      </small>
                    </div>
                  </div>

                  <div className="ms-4 ps-4"> * Em vendas o preço médio não altera </div>
                  <div className="ms-4 ps-4"> * É neste momento que apuramos lucros ou prejuízos, conforme tipo de ativo </div>
                </li>
                <li className="list-group-item">
                  <small className="d-inline-flex me-2 px-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">
                    Compra
                  </small>
                  1 unidade a

                  <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-2">
                    R$ 35,57
                  </small>

                  sem taxas totalizando a operação em

                  <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-2">
                    R$ 35,57
                  </small>

                  <div className="ms-4 pt-2 me-auto">
                    <div className="fw-bold">Preço Médio:
                      <small className="d-inline-flex ms-2 me-2 px-1 fw-semibold text-dark bg-dark bg-opacity-10 border border-dark border-opacity-10 rounded-2">
                        R$ 37,34
                      </small>
                    </div>
                  </div>
                  <div className="ms-4 ps-4"> * Permanecemos com 2 unidades</div>
                </li>
              </ol>
            </div>

            <h2 className="text-success mt-4">A importância do preço médio</h2>

            <div className="py-4">
              <p>
                O preço médio é fundamental para calcular o lucro de suas operações na bolsa.
                Ele permite que você conheça o valor exato pago pelos ativos,
                possibilitando uma análise precisa do desempenho da sua carteira.
                Além disso, o preço médio é necessário para a declaração correta no imposto de renda,
                o que torna seu cálculo essencial tanto para o controle financeiro quanto para questões fiscais.
              </p>
            </div>

            <h2 className="text-success">Observações</h2>

            <div className="py-4">
              <p className="text-decoration-underline"> Este site não se responsabiliza pela precisão dos dados, é critério exclusivo do investidor verificar e atentarse pelos
                dados fornecidos. Ele funciona apenas como ferramenta auxiiar com o proposito de ajudar. </p>
            </div>

          </article>

          <div className="p-2" style={{
            flexGrow: 1,
          }}>
            {/* RIGHT SECONDARY*/}
          </div>
        </div>
      </div>

      <div className="p-2" style={{
        flexGrow: 1,
      }}>
        {/* RIGHT MAIN*/}
      </div>
    </div>
  );
};
