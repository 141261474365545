import "./styles.css";

import { PoliticaPrivacidade, Footer } from "./pages";

function PagePoliticaPrivacidade() {

  return (
    <div className="page">
      <PoliticaPrivacidade/>

      <Footer />
    </div>
  );
}

export default PagePoliticaPrivacidade;
