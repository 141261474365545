export const calcularRateio = (fields, totalNota, calcularApuracao = false) => {

  var Taxas = 0;
  var TotalNota = 0;
  // eslint-disable-next-line
  var precoTotal = 0;
  var pesoOperacao = 0;
  var valorRateio = 0;

  TotalNota = totalNota.replace(/[^0-9]/g, "") / 100;

  fields.forEach(function (field, indice, array) {
    pesoOperacao = 0;


    if (field.preco !== "") {
      precoTotal = field.precototal.replace(/[^0-9]/g, "") / 100;
    }

    const totalOperacoes = fields.reduce((acc, val) => {
      let vlr = 0;

      vlr = val.precototal.replace(/[^0-9]/g, "") / 100;

      return (acc += vlr);

    }, 0);

    Taxas = TotalNota - totalOperacoes;

    pesoOperacao = (field.precototal.replace(/[^0-9]/g, "") * 100) / totalOperacoes;

    var porcentagem = pesoOperacao / 100 / 100;

    valorRateio = porcentagem * Taxas;

    if (calcularApuracao) {
      field.valorrateio = valorRateio;
    }
  });
};