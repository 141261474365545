import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
export const Footer = () => {

  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/politicaprivacidade");
  };
  
  return (
    <footer className="footer text-center p-5">

      <p className="pt-3 fs-6">
        {/* eslint-disable-next-line */}
        <a href="#"
          onClick={(e) => handleClick(e)}
          className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text-success"
          style={{
            textDecoration: "none !important"
          }}
        >
          Políticas de Privacidade e Responsabilidade
        </a>
      </p>

      <p className="color-footer">
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .typeString("Desenvolvido por Leomar Vaz Sartor")
              .start();
          }}
        />
      </p>
    </footer>
  );
};